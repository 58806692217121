import React from "react"
import styles from "../styles/404.module.css"
import dots from "../images/dots.svg"
import Menu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const NotFound = () => {
  return (
    <>
      <CookieConsent locale="de" />
      <div className={styles.container}>
        <Helmet htmlAttributes={{ lang: "de" }}>
          <title>404</title>
        </Helmet>
        <Menu page = "index" bg = "dark"/>
        <div className={styles.mainElementsContainer}>
          <div className={styles.content}>
            <div className={styles.textAndButtonContainer}>
              <h2>Ups..</h2>
              <p>die gewünschte Seite wurde leider nicht gefunden</p>
              <a href='/'><button className={styles.button}>zur Startseite</button></a>
            </div>
              <span className={styles.four04}>404</span>
          </div>
          <div className={styles.DotsContainer}>
            <img src={dots} alt="dots" />
          </div>
        </div>
        <div className={styles.bottomTextContainer}>
          <span>Agentur für digitale Wertschöpfung</span>
        </div>
      </div>
    </>
  )
}

export default NotFound